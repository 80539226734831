// ローディング状態を変更
export const CHANGE_LOADING_STATUS = "CHANGE_LOADING_STATUS";
export const CHANGE_IS_FOREGROUND = "CHANGE_IS_FOREGROUND";
// ステータスに応じた文言をセット
export const SET_STATUS_ID_CHAR = "SET_STATUS_ID_CHAR";
// 注文受付の件数を集計した数をセット
export const SET_NEW_ORDER_NUMBER = "SET_NEW_ORDER_NUMBER";
// UIDをセット
export const SAVE_LINE_ID = "SAVE_LINE_ID";

/**
 * ログイン
 */
export const CHANGE_LOGIN_STATUS = "CHANGE_LOGIN_STATUS"; // ログイン状態を変更
export const SET_IS_LOGIN_SUCCESS = "SET_IS_LOGIN_SUCCESS"; // ログインが成功したかどうかをセットする
export const SET_LOGIN_USER = "SET_LOGIN_USER"; // ログインしたユーザーをセットする
/**
 * 注文
 */
// 注文ステータスの更新
export const CHANGE_ORDER_STATUS = "CHANGE_ORDER_STATUS";
// 注文リストをセットする
export const SET_ORDER_LIST = "SET_ORDER_LIST";
// 注文リストを追加する
export const ADD_ORDER_LIST = "ADD_ORDER_LIST";
// 注文リストをページネーションで取得する処理の実行IDをセットする
export const SET_NOW_RUNNING_GET_ORDER_ALL_PAGINATION = "SET_NOW_RUNNING_GET_ORDER_ALL_PAGINATION";
// 注文リストを更新する(未使用)
export const UPDATE_ORDER_LSIT = "CHANGE_ORDER_LSIT";
// 単一の注文(注文詳細を表示するため)をセットする
export const SET_CURRENT_ORDER = "SET_CURRENT_ORDER";
// 単一の注文(注文詳細を表示するため)を更新する(未使用)
export const UPDATE_CURRENT_ORDER = "UPDATE_CURRENT_ORDER";
export const SET_ORDER_PDF_URL = "SET_ORDER_PDF_URL";
export const SET_ORDER_DETAIL = "SET_ORDER_DETAIL";
/**
 * お知らせ
 */
export const CHANGE_DIALOG_STATUS = "CHANGE_DIALOG_STATUS";
export const SET_CURRENT_STATUS = "SET_CURRENT_STATUS";
export const SET_NEWS_LIST = "SET_NEWS_LIST";
export const SET_CURRENT_NEWS = "SET_CURRENT_NEWS";
export const SET_IS_NEW_NEWS = "SET_IS_NEW_NEWS";
export const SET_SEND_NEW_NEWS = "SET_SEND_NEW_NEWS";
export const SET_NEWS_BLOB = "SET_NEWS_BLOB";
export const SET_NEWS_BLOB_KEY = "SET_NEWS_BLOB_KEY";
export const SET_INQUIRY_DETAILS = "SET_INQUIRY_DETAILS";
export const UPDATE_PRODUCT_LIST = "UPDATE_PRODUCT_LIST";

/**
 * 商品
 */
// すべての商品リストをセットする
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_CURRENT_PRODUCT = "SET_CURRENT_PRODUCT";
// 単位のリストをセットする
export const SET_UNIT_LIST = "SET_UNIT_LIST";
// 加工のリストをセットする
export const SET_PROCESS_LIST = "SET_PROCESS_LIST";
// 新規商品作成判定フラグをセットする
export const SET_IS_NEW_PRODUCT = "SET_IS_NEW_PRODUCT";
// 新規作成商品をセットする
export const SET_NEW_PRODUCT = "SET_NEW_PRODUCT";
// 更新した商品をセットする
export const SET_UPDATE_PRODUCT = "SET_UPDATE_PRODUCT";
// 商品一括登録用の商品リストをセットする
export const SET_BULK_PRODUCT = "SET_BULK_PRODUCT";
// 商品一括登録時に重複している商品リストをセットする
export const SET_DUPLICATE_PRODUCTS = "SET_DUPLICATE_PRODUCTS";
// 商品一括更新用の商品リストをセットする
export const SET_BULK_UPDATE_PRODUCTS = "SET_BULK_UPDATE_PRODUCTS";
export const SET_BULK_PRODUCTS_EXEC_TASK_NAME =
  "SET_BULK_PRODUCTS_EXEC_TASK_NAME";
export const SET_BULK_PRODUCTS_SAVE_STATUS = "SET_BULK_PRODUCTS_SAVE_STATUS";
export const SET_IS_PRODUCT_ID_DUPLICATE = "SET_IS_PRODUCT_ID_DUPLICATE";
export const SET_UPDATE_PRODUCTS_BY_PRODUCT_ID = "SET_UPDATE_PRODUCTS_BY_PRODUCT_ID";
export const SET_ADD_PRODUCT_PRODUCTS = "SET_ADD_PRODUCT_PRODUCTS";
export const SET_UPDATE_SORT_PRODUCTS = "SET_UPDATE_SORT_PRODUCTS";
/**
 * 得意先
 */
export const SET_CUSTOMER_ALL = "SET_CUSTOMER_ALL";
export const SET_CURRENT_CUSTOMER = "SET_CURRENT_CUSTOMER";
export const SET_IS_NEW_CUSTOMER = "SET_IS_NEW_CUSTOMER";
export const SET_NEW_CUSTOMER = "SET_NEW_CUSTOMER";
export const SET_UPDATE_CUSTOMER = "SET_UPDATE_CUSTOMER";
// 得意先一括登録用の商品リストをセットする
export const SET_BULK_CUSTOMER = "SET_BULK_CUSTOMER";
export const SET_BULK_CUSTOMERS_EXEC_TASK_NAME = "SET_BULK_CUSTOMERS_EXEC_TASK_NAME";
export const SET_BULK_CUSTOMERS_SAVE_STATUS = "SET_BULK_CUSTOMERS_SAVE_STATUS";
/**
 * 得意先の従業員
 */
export const SET_CUSTOMER_ALL_STAFF = "SET_CUSTOMER_ALL_STAFF";
export const SET_CURRENT_CUSTOMER_STAFF = "SET_CURRENT_CUSTOMER_STAFF";
export const SET_IS_NEW_CUSTOMER_STAFF = "SET_IS_NEW_CUSTOMER_STAFF";
export const SET_SELECTED_CUSTOMER_STAFF = "SET_SELECTED_CUSTOMER_STAFF";
export const SET_UPDATE_CUSTOMER_STAFF = "SET_UPDATE_CUSTOMER_STAFF";
export const SET_QR_STAFF_PDF = "SET_QR_STAFF_PDF";
export const SET_PASS_STAFF_PDF = "SET_PASS_STAFF_PDF";
export const SET_NEW_STAFF_PDF = "SET_NEW_STAFF_PDF";

/**
 * 配達担当者
 */
export const SET_EMPLOYEES = "SET_EMPLOYEES";
export const SET_CURRENT_EMPLOYEE = "SET_CURRENT_EMPLOYEE";
export const SET_IS_NEW_EMPLOYEE = "SET_IS_NEW_EMPLOYEE";
export const SET_NEW_EMPLOYEE = "SET_NEW_EMPLOYEE";
export const SET_UPDATE_EMPLOYEE = "SET_UPDATE_EMPLOYEE";
export const SET_SELECTED_EMPLOYEES = "SET_SELECTED_EMPLOYEES";
/**
 * 加工
 */
export const SET_PROCESSES = "SET_PROCESSES";
export const SET_EDIT_PROCESSES = "SET_EDIT_PROCESS";
export const SET_IS_NEW_PROCESS = "SET_IS_NEW_PROCESS";
/**
 * カテゴリー
 */
export const SET_LARGE_MIDDLE_CATEGORY_LIST = "SET_LARGE_MIDDLE_CATEGORY_LIST"; // すべての大カテゴリと大カテゴリに紐づく中カテゴリのリストをセットする
export const SET_CURRENT_CATEGORY = "SET_CURRENT_CATEGORY";
export const SET_IS_NEW_CATEGORY = "SET_IS_NEW_CATEGORY";
export const SET_NEW_CATEGORY = "SET_NEW_CATEGORY";
export const SET_UPDATE_CATEGORY = "SET_UPDATE_CATEGORY";
export const SET_IS_UPLOADING = "SET_IS_UPLOADING";
/**
 * 設定
 */
export const SET_HOLIDAY_JSON = "SET_HOLIDAY_JSON";
/**
 * 各画面のページ番号
 */
export const SET_CUSTOMER_LIST_AREA_CURRENT_PAGE = "SET_CUSTOMER_LIST_AREA_CURRENT_PAGE";
export const SET_PRODUCT_LIST_AREA_CURRENT_PAGE = "SET_PRODUCT_LIST_AREA_CURRENT_PAGE";