<template>
  <div class="fixedBody" :class="customClass">
    <div id="loading">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "LoadingArea",
  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
      isForeground: state => state.isForeground,
    }),
    customClass() {
      let styleClass = '';
      // ローディングを表示するかチェック
      if (this.isLoading) {
        console.log("this.isForeground", this.isForeground)
        // ローディング画面を最前面に表示するかチェックする
        if (this.isForeground) {
          styleClass = 'foreground';
        }
      } else {
        styleClass = 'd-none'; 
      }
      console.log("styleClass", styleClass)
      return styleClass;
    }
  }
};
</script>
<style scoped>
.d-none.fixedBody {
  display: none;
}
.foreground {
  z-index: 999 !important;
}
.fixedBody {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 997;
}

.loader,
.loader:before,
.loader:after {
  background: #ffffff;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: #ffffff;
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
