import AppDialog from "./AppDialog.vue";

const Dialog = {
  install(Vue, options) {
    console.log(options);
    this.EventBus = new Vue();
    Vue.component("app-dialog", AppDialog);
    Vue.prototype.$dialog = {
      show(params) {
        Dialog.EventBus.$emit("show", params);
      },
    };
  },
};

export default Dialog;
