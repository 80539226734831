<template>
  <div>
    <MainArea />
    <individualRequestDialog v-if="isDialog" />
  </div>
</template>

<script>
import MainArea from "@/components/MainArea.vue";
import individualRequestDialog from "@/components/util/individualRequestDialog.vue";
import { mapState } from "vuex";
export default {
  name: "HomeView",
  components: {
    MainArea,
    individualRequestDialog
  },
  computed: {
    ...mapState({
      isDialog: state => state.isDialog
    })
  },
  methods: {},
  created() {
    this.isLoginCheck();
  }
};
</script>
