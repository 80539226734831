<template>
  <div id="app">
    <div class="mainArea">
      <SideBarArea v-if="isLogin && !isEmployeeRegist" />
      <div class="mainAreaInner">
        <HeaderArea v-if="isLogin && !isEmployeeRegist" />
        <BreadcrumbArea v-if="isLogin && !isEmployeeRegist" />
        <router-view />
      </div>
    </div>
    <AppDialog v-if="isLogin && !isEmployeeRegist" />
    <LoadingArea />
  </div>
</template>

<script>
import HeaderArea from "@/components/HeaderArea.vue";
import SideBarArea from "@/components/SideBarArea.vue";
import BreadcrumbArea from "@/components/BreadcrumbArea.vue";
import LoadingArea from "@/components/util/LoadingArea.vue";
import AppDialog from "@/AppDialog.vue";
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  SET_ORDER_LIST,
  SET_STATUS_ID_CHAR,
  SET_NEW_ORDER_NUMBER
} from "@/store/mutation-types";
import { LOADING_START, LOADING_STOP } from "@/store/action-types";
export default {
  data() {
    return {
      getOrderAllApi: "order/get-all-orders",
      getOrderStatusApi: "order/order-status",
      counter: 0
    };
  },
  components: {
    LoadingArea,
    SideBarArea,
    BreadcrumbArea,
    HeaderArea,
    AppDialog
  },
  computed: {
    ...mapState({
      isLogin: state => state.isLogin,
      isLoading: state => state.isLoading,
      orderStatusIdChar: state => state.orderStatusIdChar,
      orderListInfo: state => state.orderListInfo,
      newOrderNumber: state => state.newOrderNumber
    }),
    isEmployeeRegist() {
      return this.$route.name == "EmployeeRegisterIdView";
    }
  },
  methods: {
    ...mapMutations({
      setOrderList: SET_ORDER_LIST,
      setStatusIdChar: SET_STATUS_ID_CHAR,
      setNewOrderNumber: SET_NEW_ORDER_NUMBER
    }),
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP
    }),
    setMeta(route) {
      if (route.meta.title) {
        let setTitle = route.meta.title;
        document.title = setTitle;
      }
      if (route.meta.desc) {
        let setDesc = route.meta.desc;
        if (document.querySelector("meta[name='description']")) {
          document
            .querySelector("meta[name='description']")
            .setAttribute("content", setDesc);
        }
      }
    },
    async calcNewOrderNumber() {
      await this.getOrderAll();
      this.counter = 0;
      for (let i = 0; this.orderListInfo.length > i; i++) {
        if (this.orderListInfo[i].order_status_id == "1") {
          this.counter++;
        }
      }
      console.log(this.counter);
      await this.setNewOrderNumber(this.counter);
      await this.$forceUpdate();
    },
    async getOrderStatus() {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + this.getOrderStatusApi;
      await axios
        .get(apiUrl, {
          params: {}
        })
        .then(response => {
          if (response && response.data && response.data.result) {
            console.log(response.data);
            this.setStatusIdChar(response.data.result);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    async getOrderAll() {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + this.getOrderAllApi;
      await axios
        .get(apiUrl, {
          params: {}
        })
        .then(response => {
          console.log(response);
          if (response && response.data && response.data.result) {
            console.log(response.data);
            this.setOrderList(response.data.result);
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  },
  async created() {
    await Promise.all([
      // await this.loadingStart(),
      // await this.getOrderStatus(),
      // await this.getOrderAll(),
      // await this.calcNewOrderNumber(),
      // await this.loadingStop()
    ]);
    // TODO 開発中はコメントアウト推奨
    // setInterval(() => {
    //   this.calcNewOrderNumber();
    // }, 3000);
  },
  mounted() {
    let route = this.$route;
    this.setMeta(route);
  },
  watch: {
    $route(route) {
      this.setMeta(route);
    }
  }
};
</script>

<style>
.mainArea {
  display: flex;
  background: #e3e7e7;
}
.mainArea .mainAreaInner {
  padding-top: 90px;
  padding-left: 16%;
  display: flex;
  width: 100%;
  height: 100%;
  /* max-width: 1280px; */
  flex-direction: column;
}
.mainArea .mainAreaInner > div:not(.breadcrumbArea) > div {
  background: #fff;
  margin: 24px 24px 24px;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15);
  padding: 24px;
  height: 100%;
}
.mainArea .mainAreaInner > div:not(.breadcrumbArea) h2 {
  font-size: 1.5rem;
  margin-bottom: 24px;
}
.mdiIcon {
  width: 20px;
  display: block;
  margin-right: 8px;
}
.mdiIcon > svg > path {
  fill: #fff;
}
div.iconSet {
  display: flex;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: none;
  font-style: normal;
  text-align: left;
  zoom: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
table {
  border-collapse: collapse;
  font-family: inherit;
}
h1,
h2,
h3,
h4,
h5 {
  font-size: 100%;
  font-weight: normal;
  line-height: 1;
}
input,
textarea,
select {
  font-family: inherit;
  font-size: 16px;
}
input[type="button"],
input[type="text"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}
textarea {
  resize: none;
  -webkit-appearance: none;
  border-radius: 0;
}
th,
td {
  border-collapse: collapse;
}
table th,
table td {
  white-space: nowrap;
}
ul,
ol {
  list-style-type: none;
}
img {
  vertical-align: text-bottom;
  vertical-align: -webkit-baseline-middle;
  max-width: 100%;
  height: auto;
  width: auto;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  height: 100%;
  font-family: "Noto Sans JP", sans-serif;
}
.d-none {
  display: none !important;
}
*:focus {
  outline: none;
}
input {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
/* * {
  color: #2c3b41;
} */
html {
  height: auto;
  background: #fff;
}
#app > div {
  height: 100%;
  min-height: 100vh;
  width: 100%;
}
#app {
  height: auto;
  margin: 0;
  width: 100%;
  background: #fff;
}
button {
  cursor: pointer;
}
.customTable {
  width: 100%;
  border-top: solid 1px #3c8dbc;
  overflow-x: auto;
}
.customTable .customRow {
  font-size: 0.9rem;
  display: flex;
  padding: 0;
  justify-content: flex-start;
  align-items: stretch;
}
.customTable .customRow .customColumn {
  width: 100%;
  text-align: left;
  margin-right: 0;
  padding: 16px 8px;
  border-bottom: solid 1px #3c8dbc;
}
.customTable .customRow .customColumn.minSpace {
  width: 118px!important;
  min-width: 118px!important;
}
.customTable .customRow .customColumn.moreMinSpace {
  width: 103px!important;
  min-width: 103px!important;
}
.customTable .customRow .customColumn.mediumSpace {
  width: 130px!important;
  min-width: 130px!important;
}
.customTable .customRow .customColumn:last-child {
  margin-right: 0;
}
.customTable .customRow .customColumn button {
  color: #fff;
  background: #3c8dbc;
  border-radius: 4px;
  height: 30px;
  width: 70px;
  border: none;
}
</style>
