import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import gl from "./mixins/global";
import Dialog from "./dialog";
/** element-ui start*/
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import locale from 'element-ui/lib/locale/lang/ja'
/** element-ui end*/

/** bootstrapVue start*/
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
/** bootstrapVue end*/

/** vue-select start */
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'; 
/** vue-select end */

/** v-calendar start */
import VCalendar from 'v-calendar';
// import 'v-calendar/lib/v-calendar.min.js';
/** v-calendar end */

/** bootstrapVue start*/
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
/** bootstrapVue end*/

Vue.mixin(gl);
Vue.config.productionTip = false;
Vue.use(Dialog);
/** element-ui start*/
Vue.use(ElementUI, { locale })
/** element-ui end*/

/** vue-select start */
Vue.component('v-select', vSelect);
/** vue-select end */

/** v-calendar start */
Vue.use(VCalendar);
/** v-calendar end */

function isSmartPhone() {
  if (window.matchMedia && window.matchMedia('(max-device-width: 640px)').matches) {
    return true;
  } else {
    return false;
  }
}
// リロード時の遷移制御
router.beforeEach((to, from, next) => {
  console.log("beforeEachto", to);
  console.log("beforeEachfrom", from);
  console.log("beforeEachnext", next);
  // スマホからしか担当者紐づけには遷移できないよう制御
  console.log("isSmartPhone()",isSmartPhone());
  if(to.name == "EmployeeRegisterIdView" && !isSmartPhone()){
    next({
      name: "EmployeeListView",
    });
  }
  // 編集中にリロードされたら各カテゴリTOPに遷移
  if (from.name == null) {
    switch (to.name) {
      case "NewsDetailView":
        next({
          name: "NewsListView",
        });
        break;

      case "ProductDetailView":
        next({
          name: "ProductListView",
        });
        break;

      case "ProductBulkRegisterView":
        next({
          name: "ProductListView",
        });
        break;

      case "OrderDetailView":
        next({
          name: "OrderListView",
        });
        break;

      case "EmployeeDetailView":
        next({
          name: "EmployeeListView",
        });
        break;

      case "CustomerDetailView":
        next({
          name: "CustomerListView",
        });
        break;

      case "CustomerStaffListView":
        next({
          name: "CustomerListView",
        })
        break;

      case "CustomerStaffDetailView":
        next({
          name: "CustomerListView",
        })
        break;

      case "ProcessDetailView":
        next({
          name: "ProcessView",
        });
        break;
      case "CategoryDetailView":
        next({
          name: "CategoryListView",
        });
        break;

      default:
        next();
        break;
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  el: '#app',
  render: (h) => h(App),
}).$mount("#app");
