import { mapMutations } from "vuex";
import { 
    CHANGE_LOGIN_STATUS,
} from "@/store/mutation-types";
export default {
    // computed: {
    //     dailyTotalTime() {
    //         return this.calcWorKTime();
    //     },
    // },
    methods: {
        ...mapMutations({
            changeLoginStatus: CHANGE_LOGIN_STATUS
        }),
        // ページ遷移を汎用的に行う関数
        pageTransition(transitionTarget, isLogin=false) {
            this.$router.push({
                name: transitionTarget,
                params: {
                  isLogin: isLogin,
                },
            });
        },
        // ログインチェック
        async isLoginCheck() {
            // sessionからログイン状態を取得
            const loginInformationJson =  localStorage.getItem('loginInformation');
            const loginInformation = JSON.parse(loginInformationJson);
            console.log("loginInformation",loginInformation);
            if (loginInformation) {
              // sessionからログインの有効期限を取得
              const expirationDate = new Date(loginInformation.expirationDate);
              // 日本時間で現在日付を取得
              const now = new Date();
              const options = {
                timeZone: 'Asia/Tokyo',
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
              };
              const jpDate = new Intl.DateTimeFormat('ja-JP', options).format(now);
              const nowDate = new Date(jpDate);
              console.log("expirationDate",expirationDate);
              console.log("jpDate",jpDate);
              console.log("nowDate",nowDate);
              // 有効期限のチェック
              if (expirationDate < nowDate) {
                // 過去日付の場合はログイン画面に遷移させる
                this.pageTransition("login");
              }
              // ログイン状態のチェック
              if (!loginInformation.isLogin) {
                this.pageTransition("login");
              }
              this.changeLoginStatus(loginInformation.isLogin);
            } else {
              // sessionが存在しない場合はログイン画面に遷移する
              this.pageTransition("login");
            }
        },
        getRndStr() {
          // 使用文字の定義
          const set_str =
            'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    
          const len_str = 8;
    
          // ランダムな文字列の生成
          let result = '';
          for (let i = 0; i < len_str; i++) {
            result += set_str.charAt(Math.floor(Math.random() * set_str.length));
          }
          return result;
        },
    }
}