import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "ゼンオキ食品受発注", desc: "ゼンオキ食品受発注" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
    meta: {
      title: "ログイン - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/process",
    name: "ProcessView",
    component: () => import("../views/ProcessView.vue"),
    meta: {
      title: "加工管理 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/processdetail",
    name: "ProcessDetailView",
    component: () => import("../views/ProcessDetailView.vue"),
    meta: {
      title: "加工詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/setting",
    name: "SettingView",
    component: () => import("../views/SettingView.vue"),
    meta: {
      title: "設定管理 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/productlist",
    name: "ProductListView",
    component: () => import("../views/ProductListView.vue"),
    meta: {
      title: "商品一覧 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/productlistsort",
    name: "ProductListSortView",
    component: () => import("../views/ProductListSortView.vue"),
    meta: {
      title: "商品の並び替え - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/productdetail",
    name: "ProductDetailView",
    component: () => import("../views/ProductDetailView.vue"),
    meta: {
      title: "商品詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/productBulkRegister",
    name: "ProductBulkRegisterView",
    component: () => import("../views/ProductBulkRegisterView.vue"),
    meta: {
      title: "商品一括登録 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/orderList",
    name: "OrderListView",
    component: () => import("../views/OrderListView.vue"),
    meta: {
      title: "注文一覧 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/orderdetail",
    name: "OrderDetailView",
    component: () => import("../views/OrderDetailView.vue"),
    meta: {
      title: "注文詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/newslist",
    name: "NewsListView",
    component: () => import("../views/NewsListView.vue"),
    meta: {
      title: "お知らせ一覧 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/newsdetail",
    name: "NewsDetailView",
    component: () => import("../views/NewsDetailView.vue"),
    meta: {
      title: "お知らせ詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/customerlist",
    name: "CustomerListView",
    component: () => import("../views/CustomerListView.vue"),
    meta: {
      title: "得意先一覧 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/customerdetail",
    name: "CustomerDetailView",
    component: () => import("../views/CustomerDetailView.vue"),
    meta: {
      title: "得意先詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/customerBulkRegister",
    name: "CustomerBulkRegisterView",
    component: () => import("../views/CustomerBulkRegisterView.vue"),
    meta: {
      title: "得意先一括登録 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/customerStaffList/:customer_id",
    name: "CustomerStaffListView",
    component: () => import("../views/CustomerStaffListView.vue"),
    meta: {
      title: "従業員一覧 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/customerStaffDetail/:customer_id/:staff_id",
    name: "CustomerStaffDetailView",
    component: () => import("../views/CustomerStaffDetailView.vue"),
    meta: {
      title: "従業員詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/employeelist",
    name: "EmployeeListView",
    component: () => import("../views/EmployeeListView.vue"),
    meta: {
      title: "担当者一覧 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/employeedetail",
    name: "EmployeeDetailView",
    component: () => import("../views/EmployeeDetailView.vue"),
    meta: {
      title: "担当者詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/categorylist",
    name: "CategoryListView",
    component: () => import("../views/CategoryListView.vue"),
    meta: {
      title: "カテゴリー一覧 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/categorydetail",
    name: "CategoryDetailView",
    component: () => import("../views/CategoryDetailView.vue"),
    meta: {
      title: "カテゴリー詳細 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
  {
    path: "/employeeRegisterId",
    name: "EmployeeRegisterIdView",
    component: () => import("../views/EmployeeRegisterIdView.vue"),
    meta: {
      title: "担当者ID登録 - ゼンオキ食品受発注",
      desc: "ゼンオキ食品受発注",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
