<template>
  <div class="dialog">
    <div class="body">
      <div class="location">
        <p>拠点</p>
        <select>
          <option value>加工場</option>
        </select>
      </div>

      <div class="elem">
        <p>依頼事項</p>
        <textarea cols="30" rows="10" :value="textArea"></textarea>
      </div>

      <div class="sendArea">
        <button @click="hideDialog" style="margin-right:24px;">戻る</button>
        <button @click="individualRequest">送信</button>
      </div>
    </div>
    <div class="overlay" @click="hideDialog"></div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations, mapActions } from "vuex";
import {
  LOADING_START,
  LOADING_STOP,
  DIALOG_START,
  DIALOG_STOP
} from "@/store/action-types";
import {
  SET_ORDER_LIST,
  SET_CURRENT_ORDER,
  UPDATE_ORDER_LIST,
  UPDATE_CURRENT_ORDER,
  SET_CURRENT_STATUS
} from "@/store/mutation-types";
export default {
  name: "individualRequestDialog",
  data() {
    return {
      textArea: "",
      getOrderAllApi: "order/get-all-orders",
      getOrderStatusApi: "order/order-status",
      updateOrderStatusApi: "order/update-order-status",
      currentStatus: null,
      displayOrder: null
    };
  },
  computed: {
    ...mapState({
      mainAreaMode: state => state.mainAreaMode,
      isLoading: state => state.isLoading,
      newOrderNumber: state => state.newOrderNumber,
      orderListInfo: state => state.orderListInfo,
      isDialog: state => state.isDialog,
      currentOrder: state => state.currentOrder,
      orderStatusIdChar: state => state.orderStatusIdChar
    })
  },
  methods: {
    ...mapMutations({
      setOrderList: SET_ORDER_LIST,
      updateOrderLsit: UPDATE_ORDER_LIST,
      setCurrentOrder: SET_CURRENT_ORDER,
      updateCurrentOrder: UPDATE_CURRENT_ORDER,
      setCurrentStatusStore: SET_CURRENT_STATUS
    }),
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP,
      dialogStart: DIALOG_START,
      dialogStop: DIALOG_STOP
    }),
    async hideDialog() {
      this.dialogStop();
    },
    async chgIndividualRequest() {
      this.currentStatus = {
        order_status_id: "2",
        sort_no: "1",
        status: "加工中"
      };
    },
    async individualRequest() {
      await Promise.all([
        await this.loadingStart(),
        await this.chgIndividualRequest(),
        await this.updateOrderStatus(),
        await this.convertStatus(2),
        await this.getOrderAll(),
        await this.hideDialog(),
        await this.loadingStop()
      ]);
    },
    async updateOrderStatus() {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + this.updateOrderStatusApi;
      let cloneOrder = Object.assign({}, this.currentOrder);
      cloneOrder.order_status_id = this.currentStatus.order_status_id;
      const body = {
        order: cloneOrder
      };
      await axios
        .post(apiUrl, body)
        .then(response => {
          // ここでログイン認証のstoreを変更
          if (response && response.data && response.data.result) {
            // 更新後のorderをセットする
            this.setCurrentOrder(response.data.result);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    async getOrderAll() {
      const apiUrl = process.env.VUE_APP_API_DOMAIN + this.getOrderAllApi;
      await axios
        .get(apiUrl, {
          params: {}
        })
        .then(response => {
          console.log(response);
          if (response && response.data && response.data.result) {
            console.log(response.data);
            this.setOrderList(response.data.result);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    async convertStatus(id) {
      this.currentStatus = this.orderStatusIdChar.find(
        item => item.order_status_id == id
      );
      this.setCurrentStatusStore(this.currentStatus);
      console.log(this.currentStatus);
    }
  },
  async created() {
    this.displayOrder = this.currentOrder;
    await Promise.all([
      await this.loadingStart(),
      await this.convertStatus(this.displayOrder.order_status_id),
      await this.loadingStop()
    ]);
  }
};
</script>

<style scoped>
.dialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
}
.dialog .overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 102;
  top: 0;
  left: 0;
}
.dialog .body {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 0px 2px 2px rgb(0 0 0 / 15%);
  width: 700px;
  height: auto;
  z-index: 103;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 36px 0;
  border-radius: 8px;
}
.dialog .body > * {
  display: block;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
.dialog .body select {
  padding: 8px 8px;
  border-color: #3c8dbc;
  border-radius: 4px;
  border-width: 2px;
  width: 100%;
}
.dialog .body textarea {
  margin: 0 auto;
  border-radius: 4px;
  border-color: #3c8dbc;
  padding: 8px;
  border-width: 2px;
  width: 100%;
}
.dialog .body > .elem {
  margin: 24px auto;
}
.dialog .body .sendArea {
  text-align: right;
}
.dialog .body .sendArea > button {
  max-width: 120px;
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.2);
  width: 100%;
  padding: 8px;
  border-radius: 6px;
  border: none;
  height: 45px;
  color: #fff;
  font-weight: bold;
  background: #3c8dbc;
}
</style>
