<template>
  <div class="breadcrumbArea">
    <div
      v-if="secondLevel != {} && secondLevel"
      @click="pageTransition(secondLevel.to)"
    >{{secondLevel.title}}</div>
    <span v-if="isThirdLevel" class="mdiIcon">
      <svg viewBox="0 0 24 24">
        <path :d="iconChevronRight" />
      </svg>
    </span>
    <div v-if="thirdLevel != {} && isThirdLevel" @click="queryToMove">
      {{thirdLevel.title.trim()}}<span v-if="!isFourthLevel">{{ titleMode }}</span>
    </div>
    <span v-if="isFourthLevel" class="mdiIcon">
      <svg viewBox="0 0 24 24">
        <path :d="iconChevronRight" />
      </svg>
    </span>
    <div v-if="fourthLevel != {} && isFourthLevel">{{fourthLevel.title}}{{ titleMode }}</div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { mdiChevronRight } from "@mdi/js";
import { LOADING_START, LOADING_STOP } from "@/store/action-types";
import {
  SET_ORDER_LIST,
  SET_IS_NEW_CUSTOMER_STAFF
} from "@/store/mutation-types";
export default {
  name: "BreadcrumbArea",
  data() {
    return {
      iconChevronRight: mdiChevronRight,
      new: "new",
      current: {}
    };
  },
  computed: {
    ...mapState({
      isNewNews: state => state.isNewNews,
      isNewCustomer: state => state.isNewCustomer,
      isNewProduct: state => state.isNewProduct,
      isNewEmployee: state => state.isNewEmployee,
      isNewCategory: state => state.isNewCategory,
      mainAreaMode: state => state.mainAreaMode,
      isNewCustomerStaff: state => state.isNewCustomerStaff
    }),

    isSecondLevel() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case "EmployeeListView":
        case "EmployeeDetailView":
        case "ProcessView":
        case "ProcessDetailView":
        case "OrderListView":
        case "OrderDetailView":
        case "NewsListView":
        case "NewsDetailView":
        case "SettingView":
        case "CustomerListView":
        case "CustomerDetailView":
        case "ProductListView":
        case "ProductDetailView":
        case "ProductListSortView":
        case "ProductBulkRegisterView":
        case "CategoryListView":
        case "CategoryDetailView":
        case "EmployeeRegisterIdView":
        case "CustomerStaffListView":
        case "CustomerStaffDetailView":
          return true;
        default:
          return false;
      }
    },
    isThirdLevel() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case "OrderDetailView":
        case "NewsDetailView":
        case "CustomerDetailView":
        case "ProductDetailView":
        case "ProductListSortView":
        case "ProductBulkRegisterView":
        case "EmployeeDetailView":
        case "CategoryDetailView":
        case "EmployeeRegisterIdView":
        case "CustomerStaffListView":
        case "CustomerStaffDetailView":
          return true;
        default:
          return false;
      }
    },
    isFourthLevel() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case "CustomerStaffDetailView":
          return true;
        default:
          return false;
      }
    },
    secondLevel() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case "OrderListView":
        case "OrderDetailView":
          return { title: "注文一覧", to: "OrderListView" };
        case "NewsListView":
        case "NewsDetailView":
          return { title: "お知らせ一覧", to: "NewsListView" };
        case "SettingView":
          return { title: "営業日管理", to: "SettingView" };
        case "CustomerListView":
        case "CustomerDetailView":
        case "CustomerStaffListView":
        case "CustomerStaffDetailView":
          return { title: "得意先一覧", to: "CustomerListView" };
        case "ProductListView":
        case "ProductDetailView":
        case "ProductListSortView":
        case "ProductBulkRegisterView":
          return { title: "商品一覧", to: "ProductListView" };
        case "ProcessView":
        case "ProcessDetailView":
          return { title: "加工一覧", to: "ProcessView" };
        case "EmployeeListView":
        case "EmployeeDetailView":
        case "EmployeeRegisterIdView":
          return { title: "担当者一覧", to: "EmployeeListView" };
        case "CategoryListView":
        case "CategoryDetailView":
          return { title: "カテゴリー一覧", to: "CategoryListView" };
        default:
          return "";
      }
    },
    thirdLevel() {
      // console.log(this.$route.name);
      switch (this.$route.name) {
        case "OrderDetailView":
          return { title: "注文詳細", to: "OrderDetailView" };
        case "NewsDetailView":
          return { title: "お知らせ", to: "NewsDetailView" };
        case "CustomerDetailView":
          return { title: "得意先", to: "CustomerDetailView" };
        case "ProductDetailView":
          if(this.isNewProduct){
            return { title: "商品個別登録", to: "ProductDetailView" };
          }else{
            return { title: "商品編集", to: "ProductDetailView" };
          }
        case "ProductListSortView":
          return { title: "商品の並び替え", to: "ProductListSortView" };
        case "ProductBulkRegisterView":
          return { title: "商品一括登録", to: "ProductBulkRegisterView" };
        case "ProcessDetailView":
          return { title: "加工詳細", to: "ProcessView" };
        case "EmployeeDetailView":
          return { title: "担当者", to: "EmployeeDetailView" };
        case "CategoryDetailView":
          return { title: "カテゴリー詳細", to: "CategoryDetailView" };
        case "EmployeeRegisterIdView":
          return { title: "担当者ID登録", to: "EmployeeRegisterIdView" };
        case "CustomerStaffListView":
        case "CustomerStaffDetailView":
          return { title: "発注担当者一覧", to: "CustomerStaffDetailView" };
        default:
          return "";
      }
    },
    fourthLevel() {
      switch (this.$route.name) {
        case "CustomerStaffDetailView":
          return { title: "発注担当者", to: "CustomerStaffDetailView" };
        default:
          return "";
      }
    },
    titleMode() {
      switch (this.$route.name) {
        case "NewsDetailView":
          return this.isNewNews == this.new ? "新規作成" : "編集";
        case "CustomerDetailView":
          return this.isNewCustomer ? "新規作成" : "編集";
        // case "ProductDetailView":
        //   return this.isNewProduct ? "新規作成" : "編集";
        case "EmployeeDetailView":
          return this.isNewEmployee ? "新規作成" : "編集";
        case "CategoryDetailView":
          return this.isNewCategory ? "新規作成" : "編集";
        case "CustomerStaffDetailView":
          return this.isNewCustomerStaff ? "新規作成" : "編集";
        default:
          return "";
      }
    }
  },
  methods: {
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP
    }),
    ...mapMutations({
      setOrderList: SET_ORDER_LIST,
      setIsNewCustomerStaff: SET_IS_NEW_CUSTOMER_STAFF
    }),
    async queryToMove() {
      switch (this.fourthLevel.to) {
        case "CustomerStaffDetailView":
          this.setIsNewCustomerStaff(false);
          this.$router.push({
            name: "CustomerStaffListView",
            params: {
              customer_id: this.$route.params.customer_id
            }
          });
          break;
        default:
          // console.log("this.$route.name", this.$route.name);
          if (this.$route.name != "CustomerStaffListView") {
            this.pageTransition(this.thirdLevel.to);
          }
          break;
      }
    }
  },
  created() {}
};
</script>

<style scoped>
.breadcrumbArea > div {
  cursor: pointer;
}
.breadcrumbArea {
  display: flex;
  margin: 12px 24px 0;
}
.breadcrumbArea > .mdiIcon > svg > path {
  fill: #2c3b41;
}
.breadcrumbArea > .mdiIcon {
  width: 20px;
  display: block;
  margin: 0 12px;
}
</style>
