<template>
  <div class="dialogWindow" v-if="visible">
    <div class="overlay"></div>
    <div class="contents">
      <div class="textString">
        <h3>{{ title }}</h3>
        <p>{{ text }}</p>
      </div>
      <div :class="{'oneBtnGroup': hideBtnConfirm || hideBtnCancel, 'btnGroup': !hideBtnConfirm && !hideBtnCancel}">
        <button v-if="!hideBtnConfirm" @click="confirm" class="confirm">{{btnConfirmTitle}}</button>
        <button v-if="!hideBtnCancel" @click="hide" class="hide">{{btnCancelTitle}}</button>
      </div>
    </div>
  </div>
</template>
  <script>
import Dialog from "./dialog";
import { mapState, mapMutations } from "vuex";
import {
  SET_CURRENT_NEWS,
  SET_IS_NEW_NEWS,
  SET_CURRENT_PRODUCT,
  SET_IS_NEW_PRODUCT,
  SET_CURRENT_ORDER,
  SET_CURRENT_EMPLOYEE,
  SET_IS_NEW_EMPLOYEE,
  SET_CURRENT_CUSTOMER,
  SET_IS_NEW_CUSTOMER,
  SET_EDIT_PROCESSES
} from "@/store/mutation-types";
export default {
  name: "AppDialog",
  data() {
    return {
      visible: false,
      title: "このページを離れますか？",
      text: "編集中の変更は破棄されます。",
      type: "info",
      btnConfirmTitle: "はい",
      btnCancelTitle: "いいえ",
      hideBtnConfirm: false,
      hideBtnCancel: false, 
    };
  },
  computed: {
    ...mapState({})
  },
  beforeMount() {
    Dialog.EventBus.$on("show", params => {
      this.show(params);
    });
  },
  methods: {
    ...mapMutations({
      setCurrentNews: SET_CURRENT_NEWS,
      setIsNewNews: SET_IS_NEW_NEWS,
      setCurrentProduct: SET_CURRENT_PRODUCT,
      setIsNewProduct: SET_IS_NEW_PRODUCT,
      setCurrentOrder: SET_CURRENT_ORDER,
      setCurrentEmployee: SET_CURRENT_EMPLOYEE,
      setIsNewEmployee: SET_IS_NEW_EMPLOYEE,
      setCurrentCustomer: SET_CURRENT_CUSTOMER,
      setIsNewCustomer: SET_IS_NEW_CUSTOMER,
      setEditProcess: SET_EDIT_PROCESSES
    }),
    hide() {
      this.visible = false;
    },
    confirm() {
      if (typeof this.onConfirm === "function") {
        this.onConfirm();
        switch (this.$route.name) {
          case "NewsDetailView":
            this.setCurrentNews(null);
            this.setIsNewNews(false);
            break;
          case "ProductDetailView":
            this.currentProduct({});
            this.setIsNewProduct(false);
            break;
          case "OrderDetailView":
            this.setCurrentOrder(null);
            break;
          case "EmployeeDetailView":
            this.setIsNewEmployee(false);
            this.setCurrentEmployee({});
            break;
          case "CustomerDetailView":
            this.setIsNewCustomer(false);
            this.setCurrentCustomer({});
            break;
          case "ProcessDetailView":
            this.setEditProcess({});
            break;

          default:
            break;
        }
        this.hide();
      } else {
        this.hide();
      }
    },
    show(params) {
      this.visible = true;
      this.text = params.text;
      this.type = params.type || "info";
      this.title = params.title;
      this.btnConfirmTitle = params.btnConfirmTitle;
      this.btnCancelTitle = params.btnCancelTitle;
      this.hideBtnConfirm = params.hideBtnConfirm;
      this.hideBtnCancel = params.hideBtnCancel;
      (this.btnConfirmTitle = params.btnConfirmTitle || "OK"),
        (this.btnCancelTitle = params.btnCancelTitle || "キャンセル"),
        (this.onConfirm = params.onConfirm);
    }
  }
};
</script>
<style>
.dialogWindow {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 987;
  height: 100vh;
  width: 100vw;
  background: transparent;
}
.dialogWindow .overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 988;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
}
.dialogWindow > .contents h3 {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 24px;
  text-align: center;
}
.dialogWindow > .contents p {
  text-align: center;
  font-size: 1.3rem;
}
.dialogWindow > .contents {
  padding: 24px 0px 0;
  background: #fff;
  border-radius: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  z-index: 989;
  position: relative;
  width: 100%;
  max-width: 512px;
  height: auto;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.dialogWindow > .contents > .btnGroup {
  width: 100%;
  border-top: solid 1px #00c853;
  position: relative;
}
.dialogWindow > .contents > .oneBtnGroup {
  width: 100%;
  border-top: solid 1px #00c853;
  position: relative;
}
.dialogWindow > .contents > .textString {
  padding: 70px 0px 0;
}
.dialogWindow > .contents .btnGroup .confirm,
.dialogWindow > .contents .btnGroup .hide {
  height: 70px;
  max-width: 50%;
  width: 100%;
  border-radius: 0 0 16px 16px;
  border: none;
  background: #fff;
  font-size: 1.1rem;
}
.dialogWindow > .contents .oneBtnGroup .confirm,
.dialogWindow > .contents .oneBtnGroup .hide {
  height: 70px;
  /* max-width: 50%; */
  width: 100%;
  border-radius: 0 0 16px 16px;
  border: none;
  background: #fff;
  font-size: 1.1rem;
}
.dialogWindow > .contents > .btnGroup::before {
  content: "";
  background: #00c853;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  height: 70px;
  width: 1px;
}
</style>