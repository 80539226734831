<template>
  <div class="sideBar">
    <div class="sideBarInner">
      <!-- <div class="iconSet" @click="pageTransition('home')">
        <span class="mdiIcon">
          <svg viewBox="0 0 24 24">
            <path :d="iconHome" />
          </svg>
        </span>
        ホーム
      </div> -->
      <div class="inHome">
        <div class="newOrderNum" @click="pageTransition('OrderListView')">
          注文情報
          <!-- <span v-if="newOrderNumber > 0">{{ newOrderNumber }}</span> -->
        </div>
        <div @click="pageTransition('NewsListView')">お知らせ管理</div>
        <!-- <div>統計情報</div> -->
        <div class="accordion">
          <div class="card-header" :id="'heading'">
            <button class="btn" type="button" data-toggle="collapse" :data-target="'#collapse'" aria-expanded="true" :aria-controls="'collapse'" @click="toggle" style="color: #fff">
              <b-icon class="icon" v-if="isAccordionOpen" icon="chevron-down" variant="white"></b-icon>
              <b-icon class="icon" v-else icon="chevron-right" variant="white"></b-icon>
              設定
            </button>
          </div>
          <div :id="'collapse'" class="collapse" :class="{ show: isAccordionOpen }" :aria-labelledby="'heading'" data-parent="#accordionExample">
            <div class="card-body">
              <div @click="pageTransition('ProductListView')">商品管理</div>
              <div class="text-white" @click="pageTransition('CustomerListView')">得意先管理</div>
              <div class="text-white" @click="pageTransition('EmployeeListView')">担当者管理</div>
              <div @click="pageTransition('SettingView')">営業日管理</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="iconSet" @click="pageTransition('SettingView')">
        <span class="mdiIcon">
          <svg viewBox="0 0 24 24">
            <path :d="iconCog" />
          </svg>
        </span>
        設定
      </div> -->
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import { mdiChevronRight, mdiCog, mdiHome } from "@mdi/js";
import { mapState, mapMutations } from "vuex";
import {
  SET_ORDER_LIST,
  SET_STATUS_ID_CHAR,
  SET_NEW_ORDER_NUMBER
} from "@/store/mutation-types";
export default {
  name: "SideBarArea",
  data() {
    return {
      iconChevronRight: mdiChevronRight,
      iconCog: mdiCog,
      iconHome: mdiHome,
      // getOrderAllApi: "order/get-all-orders",
      getOrderStatusApi: "order/order-status",
      isAccordionOpen: false,
    };
  },
  computed: {
    ...mapState({
      mainAreaMode: state => state.mainAreaMode,
      isLoading: state => state.isLoading,
      orderStatusIdChar: state => state.orderStatusIdChar,
      newOrderNumber: state => state.newOrderNumber,
      orderListInfo: state => state.orderListInfo
    })
  },
  watch: {
    newOrderNumber(val) {
      console.log(val);
      this.$forceUpdate();
    }
  },
  methods: {
    ...mapMutations({
      setOrderList: SET_ORDER_LIST,
      setStatusIdChar: SET_STATUS_ID_CHAR,
      setNewOrderNumber: SET_NEW_ORDER_NUMBER
    }),
    // async getOrderAll() {
    //   const apiUrl = process.env.VUE_APP_API_DOMAIN + this.getOrderAllApi;
    //   await axios
    //     .get(apiUrl, {
    //       params: {}
    //     })
    //     .then(response => {
    //       console.log(response);
    //       if (response && response.data && response.data.result) {
    //         this.setOrderList(response.data.result);
    //         this.setNewOrderNumber(this.orderListInfo.length);
    //       }
    //     })
    //     .catch(err => {
    //       console.error(err);
    //     });
    // },
    toggle() {
      this.isAccordionOpen = !this.isAccordionOpen;
    }
  },
  created() {}
};
</script>

<style scoped>
.sideBar .newOrderNum {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.sideBar .newOrderNum span {
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  background: #3c8dbc;
  color: #fff;
}

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.sideBar {
  background: #2c3b41;
  padding: 24px 24px;
  width: 16%;
  min-height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
}
.sideBar .sideBarInner > div:first-child {
  padding-top: 0;
}
.sideBar .sideBarInner > div {
  color: #fff;
  cursor: pointer;
  padding: 8px 0;
}
.sideBar .sideBarInner > .inHome > div:first-child {
  padding-top: 0;
}
.sideBar .sideBarInner > .inHome > div {
  color: #fff;
  padding: 8px 0 8px 0px;
  cursor: pointer;
}
.sideBar .sideBarInner > div > .mdiIcon {
  width: 20px;
  display: block;
  margin-right: 8px;
}
.sideBar .sideBarInner > div > .mdiIcon > svg > path {
  fill: #fff;
}
.sideBar .sideBarInner > div.iconSet {
  display: flex;
}
.sideBar .sideBarInner .icon {
  font-size: 1.1rem;
}
.sideBar .sideBarInner .btn {
  text-align: left;
  padding: 0;
}
.sideBar .sideBarInner .card-body > div {
  margin-left: 1.3rem;
  padding: 4px 0;
}
</style>
