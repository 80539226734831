<template>
  <div class="homeArea">
    <h2>新着情報</h2>
    <div class="customTable">
      <div class="customRow" v-for="(item, index) in newOrderInfoLocal" :key="index">
        <div class="customColumn">{{ item.time}}</div>
        <div class="customColumn">{{ item.sentence}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { SET_NEW_ORDER, UPDATE_NEW_ORDER } from "@/store/mutation-types";

export default {
  name: "OrderDetailArea",
  data() {
    return {
      updateOrderStatusApi: "order/update-order-status",
      newOrderInfoLocal: [
        {
          time: "2022/12/25 12:35",
          sentence: "ABC商店様より注文が入りました。"
        },
        {
          time: "2022/12/25 16:35",
          sentence: "ABC商店様より注文が入りました。"
        },
        {
          time: "2022/12/26 19:35",
          sentence: "ABC商店様より注文が入りました。"
        },
        {
          time: "2022/12/28 22:35",
          sentence: "ABC商店様より注文が入りました。"
        },
        {
          time: "2022/12/29 12:35",
          sentence: "ABC商店様より注文が入りました。"
        },
        {
          time: "2022/12/30 09:35",
          sentence: "ABC商店様より注文が入りました。"
        },
        {
          time: "2022/12/31 06:35",
          sentence: "ABC商店様より注文が入りました。"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      // isLogin: state => state.isLogin,
      mainAreaMode: state => state.mainAreaMode,
      orderListInfo: state => state.orderListInfo,
      currentOrder: state => state.currentOrder
    })
  },
  methods: {
    ...mapMutations({
      setOrderList: UPDATE_NEW_ORDER,
      updateOrderLsit: SET_NEW_ORDER
    })
  },
  async created() {
    // this.newOrderInfoLocal = this.currentOrder;
  }
};
</script>

<style scoped>
.orderDetailContents {
  display: flex;
  margin-bottom: 24px;
}
.orderDetailContents .left .detailPair {
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px;
}
.orderDetailContents .left {
  margin-right: 100px;
  max-width: 350px;
}
.orderDetailContents .left,
.orderDetailContents .right {
  width: 100%;
}
.orderDetailContents .right .buttonArea > button {
  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.2);
  color: #fff;
  background: #3c8dbc;
  border-radius: 4px;
  border: none;
  width: 140px;
  height: 45px;
}
.orderDetailContents .right .buttonArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
}
</style>