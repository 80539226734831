<template>
  <div>
    <HomeArea />
  </div>
</template>

<script>
import HomeArea from "@/components/contents/HomeArea.vue";

export default {
  name: "MainArea",
  components: {
    HomeArea
  },
  computed: {},
  methods: {},
  created() {}
};
</script>

<style scoped>
</style>
