<template>
  <header>
    <div class="logoArea">
      <!-- <img src="@/assets/logoWhite.png" alt /> -->
      <h1>管理画面</h1>
    </div>
    <div class="AuthorizationArea">
      <p>有限会社 ゼンオキ食品</p>
      <p class="loginUser">{{ employees_last_name+ " " + employees_first_name }}</p>
    </div>
  </header>
</template>

<script>
export default {
  name: "HeaderArea",
  data(){
    return{
      employees_first_name:"",
      employees_last_name:"",
    }
  },
  props: {
    // msg: String
  },
  methods:{
    setLoginInfo(){
      const loginInfoJson =  localStorage.getItem('loginInformation');
      const loginInfo = JSON.parse(loginInfoJson);
      this.employees_first_name = loginInfo.employees_first_name;
      this.employees_last_name = loginInfo.employees_last_name;
    }
  },
  created() {
    this.setLoginInfo();
    // console.log(process.env.VUE_APP_API_DOMAIN);
  }
};
</script>

<style scoped>
header {
  background: #3c8dbc;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  position: fixed;
  width: 84%;
  height: 10%;
  right: 0;
  top: 0;
  z-index: 100;
}
header .AuthorizationArea p {
  color: #fff;
  margin-bottom: 0;
}
header .AuthorizationArea .loginUser {
  text-align: right;
  font-size: 1.4rem;
}
header .logoArea img {
  max-width: 111px;
}
header .logoArea > h1 {
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
}
</style>
